import { Injectable, inject } from '@angular/core';
import { TokenService } from './token.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoginResponse } from '../models/login-response.model';
import { AuthenticationResult } from '@azure/msal-browser';
import { Login, ResetPassword, VerifyTwoFactor } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _tokenService = inject(TokenService);
  private _http = inject(HttpClient);
  private _baseUrl = `${environment.baseUrl}/Account`;
  refreshMenuFacility?: (facilityIdentifier: string | null) => void;

  login(login: Login) {
    return this._http.post<LoginResponse>(`${this._baseUrl}/login`, login);
  }
  verifyTwoFactor(data: VerifyTwoFactor) {
    return this._http.post<LoginResponse>(`${this._baseUrl}/verify-twofa`, data);
  }
  resetPassword(data: ResetPassword) {
    return this._http.post(`${this._baseUrl}/ResetPassword`, data);
  }
  recoverPassword(email: string) {
    return this._http.post(`${this._baseUrl}/RequestResetPassword`, { email });
  }
  resendAuthCode(email: string) {
    return this._http.post(`${this._baseUrl}/ResendAuthCode?email=${email}`, null);
  }

  isAuthenticated(): boolean {
    return !!this._tokenService.getToken() && !!this._tokenService.getTokenEntra();
  }

  logout(): void {
    this._http.post(`${this._baseUrl}/logout`, null).subscribe();
    this._tokenService.removeToken();
    this._tokenService.removeTokenEntra();
  }

  setCurrentFacility(selectedFacility: string | null) {
    if (selectedFacility) {
      localStorage.setItem('selectedFacility', selectedFacility);
    } else {
      localStorage.removeItem('selectedFacility');
    }
  }

  getCurrentFacility(): string | null {
    return localStorage.getItem('selectedFacility');
  }

  loginWithMicrosoft(credentials: AuthenticationResult, localAccountId: string) {
    const body = {
      accessToken: credentials.accessToken,
      idToken: credentials.idToken,
      localAccountId: localAccountId,
    };
    return this._http.post<LoginResponse>(`${this._baseUrl}/login-entra`, body);
  }
}
